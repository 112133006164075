import { db, auth } from '../config/main_config';

const onboardingCheck = (companyID) => {
  console.log(companyID);
  db.collection('customers')
    .doc(companyID)
    .get()
    .then((customerDoc) => {
      if (customerDoc.exists) {
        let customerDocData = customerDoc.data();
        let onboardingMap = customerDocData?.onboardingMap;
        console.log(onboardingMap);

        db.collection('users')
          .doc(auth.currentUser.uid)
          .get()
          .then((currentUserDoc) => {
            if (currentUserDoc.exists) {
              let currentUserDocData = currentUserDoc.data();

              if (currentUserDocData?.mspImpersonation !== true) {
                if (onboardingMap !== undefined) {
                  let initialOnboardingComplete =
                    onboardingMap?.initialOnboardingCompleted ?? false;
                  let PA1Completed = onboardingMap?.PA1Completed ?? false;
                  let PA2Completed = onboardingMap?.PA2Completed ?? false;
                  let E8Completed = onboardingMap?.E8Completed ?? false;
                  let onboardingURL;
                  let onboardingComplete;
                  console.log(initialOnboardingComplete, PA1Completed, PA2Completed, E8Completed);

                  if (
                    initialOnboardingComplete === true &&
                    PA1Completed === false &&
                    PA2Completed === false &&
                    E8Completed === false
                  ) {
                    onboardingURL = 'onboarding/#!/pa/data_types/';
                    onboardingComplete = false;
                  } else if (
                    initialOnboardingComplete === true &&
                    PA1Completed === true &&
                    PA2Completed === false &&
                    E8Completed === false
                  ) {
                    onboardingURL = 'onboarding/#!/pa/email_collab/';
                    onboardingComplete = false;
                  } else if (
                    initialOnboardingComplete === true &&
                    PA1Completed === true &&
                    PA2Completed === true &&
                    E8Completed === false
                  ) {
                    onboardingURL = 'onboarding/#!/e8/it_provider/';
                    onboardingComplete = false;
                  } else {
                    onboardingComplete = true;
                  }

                  if (!onboardingComplete) {
                    const header = document.querySelector('.header');
                    const warningBanner = document.createElement('div');
                    warningBanner.classList.add('warning-banner');
                    warningBanner.id = 'warning-banner';
                    warningBanner.style.height = '1.5rem';
                    warningBanner.style.textAlign = 'center';
                    warningBanner.style.backgroundColor = '#f6961d';
                    warningBanner.style.width = '100%';
                    warningBanner.style.marginBottom = '1.5rem';
                    warningBanner.innerHTML = `<b>Action Required</b> - Click <a href="https://login.deiterate.com/${onboardingURL}" style="color: white;">here</a> to complete your onboarding.`;
                    warningBanner.style.color = 'white';
                    header.style.marginBottom = '0 !important';
                    header.style.marginTop = '-1.5rem';
                    header.after(warningBanner);
                    const configProgressDiv = document.getElementById('configProgressDiv');
                    configProgressDiv.style.display = 'flex';
                    const continueConfigBtn = document.getElementById('actionRequiredBtn');
                    continueConfigBtn.onclick = () => (window.location = onboardingURL);
                  }
                }
              }
            }
          });
      }
    });
};

export { onboardingCheck };
